$adaptive-placeholder: (
  height: 10em
);

.adpl {
  textarea {
    @include adaptive-placeholder(
      (
        height: map-get($adaptive-placeholder, height),
        textarea: true
      )
    );
    padding-top: calc(1em - -1px);
  }
}

.site-header--sticky.site-header .site-header__primary .menu__item--lvl-1 > a {
  padding: 12px 6px;
}

.egiftcard-container {
  margin: 0px auto;
  overflow: hidden;
  padding-top: 50px;
  width: 960px;
  .egiftcard {
    &-left-content {
      float: left;
      padding-right: 50px;
      width: 600px;
      .header-image {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .egiftcard-content {
        p {
          font-size: 13px;
          line-height: 18px;
          padding-bottom: 20px;
        }
      }
      .giftcard-buttons {
        margin: 0px;
        overflow: hidden;
        padding: 0px;
        li {
          a {
            color: #2b2b2b;
            display: inline-block;
            margin-right: 5px;
            float: left;
          }
        }
      }
    }
    &-right-content {
      float: left;
      width: 175px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .contact-questions {
    input[type='text'] {
      &.order_number {
        line-height: 0;
      }
    }
  }
}

.site-header__menu {
  .site-header {
    &__menu-list--desktop {
      ul {
        &.menu--lvl-1 {
          .menu__item--lvl-1 {
            margin: 0 5px;
          }
        }
      }
    }
  }
}

.site-header__utility {
  line-height: $cart-header-line-height;
}

#cboxClose {
  width: auto;
}

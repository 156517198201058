.footer {
  .menu__list {
    @include breakpoint($portrait-only) {
      height: 189px;
    }
  }
  .country-select {
    &__selectbox {
      min-width: 160px;
    }
  }
  .site-email-signup,
  .site-footer-social-links {
    margin-left: 0px;
  }
}

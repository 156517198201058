#signin.account_page {
  #new-account {
    .form-item {
      span {
        @include swap_direction(padding, 0);
        font-size: 12px;
        line-height: 16px;
        em {
          font-size: 10px;
          line-height: 14px;
        }
      }
      fieldset {
        .form-item {
          &.sms {
            width: 100%;
          }
        }
      }
      .password-field__info {
        width: 290px;
      }
    }
  }
}

.account_page#giftcards {
  #newmyaccountsubnav {
    .accountNavItem7 {
      a {
        background: #005288;
        color: #ffffff;
        border-right: 0;
      }
    }
  }
  .myaccount.grid_container {
    .colwidth_3 {
      margin: 0 0 0 25px;
      padding: 0 0 10px;
      fieldset.giftcard {
        width: 600px;
      }
    }
  }
  .giftcardbalance-popbox {
    background-color: #fff;
    padding: 15px;
    font-size: 15px;
    font-weight: bold;
    width: 250px;
    .card-balance-title {
      border-bottom: 1px solid #afadad;
      position: relative;
      top: -2px;
      font-size: 19px;
      padding-bottom: 6px;
      margin-bottom: 10px;
    }
    .giftcardbalance-amtbox {
      text-align: center;
    }
  }
}

#registration {
  #content {
    #registration-wrapper {
      fieldset.fs {
        width: 620px;
        div.form_element {
          label {
            span {
              color: $color-gray-dark;
              font-style: normal;
            }
          }
          &.password_container {
            label {
              span {
                display: inline;
              }
            }
            .note {
              display: block;
              line-height: initial;
            }
          }
        }
        .form_bool {
          display: inline-block;
          input {
            float: #{$ldirection};
            margin: 3px 10px 0 0;
          }
          .form_multi_label {
            float: #{$ldirection};
          }
        }
        &.email_promotions {
          max-width: 100%;
          width: 682px;
          div.pc_email_promo_container {
            min-width: 910px;
            input {
              display: inline;
            }
            span.label-content {
              display: inline;
              margin-#{$ldirection}: 0em;
            }
          }
        }
      }
      #gender_container {
        .gender_container {
          width: 500px;
          margin-top: 10px;
          span {
            float: left;
            margin-#{$rdirection}: 20px;
            vertical-align: middle;
            width: 185px;
            margin-top: 0px;
          }
          label {
            float: left;
            width: auto;
            span {
              margin-#{$rdirection}: 0em;
            }
          }
          input {
            float: left;
          }
        }
      }
    }
  }
}

#order-info {
  .order_details {
    .tracking_disclaimer {
      display: none;
    }
    .tracking_link {
      position: relative;
    }
    .tracking_link:hover .tracking_disclaimer {
      display: block;
      background-color: $color-white;
      color: $color-black;
      border: 1px solid $color-black;
      border-radius: 6px;
      white-space: normal;
      font-size: 11px;
      position: absolute;
      width: 178px;
      bottom: 27px;
      z-index: 1;
    }
  }
}

#address_book {
  div.addressbook_column {
    width: 220px;
    height: auto;
  }
  .address-panel {
    height: auto;
  }
}

#payment.account_page {
  .grid_container {
    #payment_add,
    #payment_edit {
      margin: 0 29px;
      .default_payment_container {
        input {
          display: inline;
        }
        span.label-content {
          margin-#{$ldirection}: 1em;
        }
      }
    }
  }
  fieldset.payment_method {
    div.expiration_date_container {
      select {
        display: inline;
        margin-#{$rdirection}: 11px;
        width: 90px;
      }
      label {
        display: block;
      }
    }
    .card_cvn {
      label {
        display: none;
      }
    }
    div.form_element {
      select,
      input {
        margin-top: 0px;
      }
    }
  }
  fieldset.payment_address {
    select {
      height: 28px;
    }
  }
  #payment_add {
    span.required_mark {
      display: none;
    }
  }
  #payment_edit {
    span.required_mark {
      display: inline;
    }
  }
}

#purchases {
  tr.product-thumb-row {
    td.total {
      display: none;
    }
  }
}

#landing {
  .error_messages {
    width: 960px;
    margin-top: 10px;
  }
}

#address_book,
#payment {
  #cboxClose {
    right: 25px;
  }
}

.loading {
  background: url(/media/images/global/ajax-loading.gif) no-repeat center center;
  width: 100%;
}

#payment_info {
  .payment-delete {
    width: 300px;
  }
}

#address_book {
  .address-book-info {
    .addressbook-container {
      .addressbook_column {
        &.default {
          margin-right: 8px;
        }
        .other {
          color: $color-black;
          margin-bottom: 16px;
          display: block;
          font-family: arial;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #registration {
    #content {
      #birthday_container {
        width: 600px;
      }
      fieldset {
        &.fs {
          #birthday_container {
            div {
              &.form_element {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }
}

.address_form_container {
  overflow: hidden;
  #QAS_NODE {
    clear: both;
    input[type='submit'] {
      margin-top: 20px;
    }
    h3.searchforaddress {
      border-bottom: 1px solid $color-gray !important;
      font-weight: bold;
      width: 500px;
      margin-bottom: 10px;
      line-height: 38px;
    }
  }
  .adpl {
    #QAS_NODE {
      input[type='text'] + label {
        margin-top: 0px;
        &:before {
          margin-#{$ldirection}: 0em;
          color: $color-black;
        }
      }
    }
  }
  input[type='checkbox'] ~ label .label-content {
    margin-#{$ldirection}: 0em;
  }
  .form_element {
    line-height: normal !important;
    &.county {
      clear: both;
    }
    &.address2_container.country {
      height: 78px;
      width: 169px;
      padding-top: 30px;
      line-height: normal;
    }
  }
}

#cboxLoadedContent {
  #address_form_container {
    select,
    input[type='text'] {
      width: 163px;
    }
  }
}

#order-info {
  th {
    text-align: center;
    text-indent: 0px !important;
  }
}

#node-420 {
  .basic-grid--medium-cols-3 {
    .basic-grid__item {
      @include breakpoint($landscape-up) {
        min-height: 427px !important;
      }
    }
  }
}

#password_request {
  .password_request {
    #request-info {
      .form-submit {
        @include breakpoint($portrait-up) {
          width: 125px;
        }
      }
    }
  }
}

.password_reset {
  .password-field {
    width: 344px;
    &__info {
      &::before {
        top: 39%;
        right: 94%;
      }
    }
  }
}

.password-change {
  width: 960px;
  @include swap_direction(margin, 20px auto);
}

@mixin checkout_heading {
  border-bottom: 3px solid $color-grey;
  display: block;
  overflow: auto;
}

.body-container {
  width: 100%;
}

.checkout {
  #viewcart_banner {
    @include swap_direction(margin, 0 auto 20px auto);
  }
  &#viewcart {
    .samples-overlay {
      #content {
        #sample-products {
          float: left;
          @include swap_direction(padding, 20px);
          li.product {
            height: 300px;
            position: relative;
            border: 2px solid #dbdbdb;
            width: 160px;
            margin-#{$rdirection}: 10px;
            @include swap_direction(padding, 10px 10px 19px);
            font-size: 11px;
            .product-img {
              img {
                height: 120px;
              }
            }
          }
          ul.skus {
            li {
              border: none;
              padding: 0px;
              width: 137px;
              height: 30px;
              position: absolute;
              bottom: 10px;
              a {
                width: 137px;
                letter-spacing: 1px;
                padding: 0 9px;
                text-align: center;
                background-color: #000000;
                color: #ffffff;
                text-transform: uppercase;
                border: 0;
                display: inline-block;
                height: 30px;
                line-height: 30px;
              }
            }
          }
          ul.product-list {
            li.product {
              ul.skus {
                li {
                  a {
                    padding: 0;
                  }
                }
              }
            }
          }
        }
        .samples-head-wrap {
          .remaining,
          p.available {
            margin: 1em 0 1em 0;
          }
        }
        .samples-head {
          float: left;
          @include swap_direction(margin, 0 19px 0 29px);
          font-size: 16px;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      #sample-controls {
        clear: both;
        text-align: $rdirection;
        margin-bottom: 25px;
        margin-#{$rdirection}: 20px;
      }
      .samples-panel {
        .product-list > li {
          margin-top: 10px;
          &:nth-child(4n + 1) {
            clear: none;
          }
        }
      }
    }
    .pg_wrapper {
      section.viewcart-buttons-panel {
        .continue-buttons {
          a.paypal-checkout {
            &.disabled {
              img {
                padding-bottom: 0px;
              }
            }
            .paypal-message {
              text-align: center;
            }
          }
        }
      }
    }
    .cs-page {
      &__content {
        width: 100%;
      }
    }
  }
}

.samples-panel {
  .checkout {
    &#samples {
      border: none;
      #content {
        .samples-head-wrap {
          border-width: 2px;
        }
        #sample-controls {
          .form-submit {
            background-color: #{$color-black};
            color: #{$color-white};
            font-weight: bold;
          }
        }
        #sample-products {
          .product-list {
            overflow: visible;
            .product {
              &.deactivate {
                pointer-events: none;
                color: #{$color-grey};
                .product-name {
                  color: #{$color-grey};
                }
                .product-img {
                  opacity: 0.4;
                }
                .sample-select-button {
                  background-color: #{$color-grey};
                }
              }
              .product-img {
                max-width: inherit;
                img {
                  width: auto;
                }
              }
              @include swap_direction(margin, 10px 10px 0 0);
              height: 300px;
              position: relative;
              .details {
                margin-#{$ldirection}: 0;
                li {
                  @include swap_direction(padding, 0);
                  width: 100%;
                  height: 30px;
                  border: none;
                  position: absolute;
                  bottom: 10px;
                  #{$ldirection}: 0;
                  margin-bottom: 0.5em;
                  text-align: center;
                  .btn {
                    font-size: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.panel {
    border: none;
  }
}

a.continue-shopping {
  text-decoration: underline;
  display: inline-block;
}
/* VIEWCART SIDEBAR TABS */
.tab-switcher {
  .tab-bar {
    li {
      margin-#{$rdirection}: 2px;
      float: $ldirection;
      a {
        border-width: 0px;
        background-color: $color-grey;
        display: block;
        @include swap_direction(padding, 0.5em);
        color: $color-black;
        font-weight: bold;
      }
      a.active {
        color: $color-white;
        background-color: $color-black;
      }
    }
  }
  .tab-content {
    clear: both;
  }
}

section.pg_wrapper {
  img {
    vertical-align: middle;
    max-width: 100%;
  }
  div.messages {
    &:empty {
      display: none;
    }
  }
  width: 960px;
  @include swap_direction(margin, 0 auto);
  @include swap_direction(padding, 0);
  h3 {
    clear: none;
    @include swap_direction(margin, 0 0 1em);
    @include swap_direction(padding, 0);
    color: $color-black;
  }
  a.btn {
    background: #000000;
    color: #ffffff;
    cursor: pointer;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    border: 0;
    @include swap_direction(padding, 0 11px);
    display: inline-block;
    height: 30px;
    line-height: 30px;
    width: auto;
    overflow: visible;
    -webkit-font-smoothing: antialiased;
  }
  a.btn-mini {
    font-size: 12px;
  }
  input.btn-mini {
    font-size: 12px;
  }
  div.product {
    float: none;
    @include swap_direction(margin, 0 !important);
    @include swap_direction(padding, 0);
    width: 100%;
    height: auto;
    min-height: 0;
    div.price {
      @include swap_direction(margin, 0);
    }
    .price-label,
    .qty-label {
      display: none;
    }
  }
  #delivery-address-h,
  #gift-options-h,
  #gift-message-h {
    @include swap_direction(margin, 1em 0);
    h3 {
      @include swap_direction(margin, 0 0 0.3em);
      color: $color-black;
    }
  }
  .more-info-icon-popup {
    img {
      vertical-align: top;
      padding-#{$ldirection}: 4px;
    }
  }
  .set-collection-point {
    a.btn {
      @include swap_direction(padding, 0.4em 1em 0.3em);
      line-height: 22px;
      display: block;
      width: auto;
      float: $ldirection;
    }
  }
}

.field-are-required {
  float: $rdirection;
}

#shipping-panel {
  #checkout_shipping_panel {
    .form-item {
      #form--sms--field--MOBILE_PHONE {
        width: 80% !important;
      }
    }
    .new-address {
      .form-item {
        input {
          width: 100%;
        }
      }
    }
  }
  section.gift-options {
    @include swap_direction(margin, 1em -1em);
    @include swap_direction(padding, 1em);
    clear: both;
    .giftwrap {
      @include swap_direction(margin, 0);
    }
    img {
      position: absolute;
      top: 1em;
      #{$rdirection}: 1em;
      width: 100px;
      height: 100px;
    }
    div.form-item.giftwrap {
      margin-bottom: 15px;
      input {
        float: $ldirection;
        margin-#{$rdirection}: 5px;
        display: inline-block;
        width: 14px;
      }
      label {
        display: inline-block;
        width: 94%;
      }
    }
    div.form-item.card-message {
      width: 100%;
      clear: both;
      float: none;
    }
    #gift-max-char {
      text-align: $rdirection;
      em {
        font-style: normal;
      }
    }
    #gift-max-char.gift-msg-max-length-exceeded {
      color: #ae0001;
    }
    .sub-section {
      @include swap_direction(margin, 15px 0 0 0);
    }
  }
  div.content {
    border-bottom: 1px none $color-grey;
  }
  div#shipping-address-display {
    border-#{$rdirection}: 1px solid $color-grey;
    padding-#{$rdirection}: 15px;
    width: 50%;
    &.ship-address {
      border: none;
      width: 100%;
    }
  }
  div.content.column {
    width: 100%;
  }
  .select-address {
    input[type='radio'] {
      @include swap_direction(margin, 0);
      vertical-align: baseline;
    }
  }
  #gift-options-display {
    margin-#{$ldirection}: 15px;
    width: 41%;
    &.gift-display {
      width: 100%;
      .panel {
        &-left {
          clear: none;
          float: #{$ldirection};
          width: 41%;
        }
        &-right {
          clear: none;
          float: #{$ldirection};
          width: 53%;
        }
      }
    }
  }
  #continue-btn {
    float: $rdirection;
  }
}

#offer-code-panel {
  form {
    input#form--offer_code--field--OFFER_CODE {
      width: 70%;
    }
    input.form-submit {
      width: 25%;
      text-align: center;
    }
  }
  overflow: auto;
  border-bottom: 3px solid $color-grey;
  header {
    border-bottom: 1px solid $color-grey;
    display: block;
    overflow: auto;
  }
  p {
    font-size: 13px;
  }
  form.adpl {
    input.form-submit {
      margin-#{$ldirection}: 10px;
    }
  }
}

div.column {
  section.panel {
    select,
    select.field,
    textarea.field,
    input.field {
      width: 90%;
      border: 1px solid $color-grey;
      font-size: 12px;
    }
    fieldset {
      @include swap_direction(margin, 0);
    }
    div.form_element {
      width: 100%;
      float: none;
      @include swap_direction(margin, 0.5em 0);
      label {
        width: 100%;
        font-weight: bold;
      }
    }
    div.form-item {
      width: 100%;
      float: none;
      @include swap_direction(margin, 1em 0);
      label {
        width: 100%;
        font-weight: bold;
      }
      label.form-element-prefix {
        width: auto;
        position: relative;
      }
      &.card-cvn {
        a.security-code {
          display: block;
        }
      }
      &.card-expiry {
        @include swap_direction(margin, 0 0 1em 0);
        width: 45%;
        float: #{$ldirection};
      }
      .section-separation {
        margin-top: 10px;
        float: #{$ldirection};
        width: 5%;
      }
    }
    div.form-item.full-width {
      float: none;
      width: 100%;
      clear: both;
    }
    select.error,
    textarea.error input.error {
      border-color: #b50404;
      color: #b50404;
    }
    div.form-item.checkbox {
      input.field {
        width: 5% !important;
      }
    }
    section {
      margin-bottom: 1em;
    }
    section.divide {
      padding-top: 1em;
    }
    #new-account {
      width: 47.9%;
      margin-#{$rdirection}: 2%;
    }
    #return-user {
      width: 47.9%;
      margin-#{$ldirection}: 2%;
      clear: none;
    }
    div.form-item.pc_email_promo_container {
      label {
        margin-#{$ldirection}: 5px;
      }
    }
    div.form-item.address-to-use {
      label {
        display: inline-block;
        width: inherit;
      }
    }
    .pc_email_promo_container {
      label {
        margin-#{$ldirection}: 0;
      }
    }
    .privacy_policy_container {
      label {
        margin-#{$ldirection}: 0;
      }
    }
    .product {
      .qty {
        select {
          @include swap_direction(padding, 3px 20px 3px 3px);
        }
      }
    }
    .ship-method-group.ship-method-home-group {
      select {
        @include swap_direction(padding, 3px 20px 3px 3px);
      }
    }
    .set-collection-point {
      div.form-item {
        overflow: visible;
        width: 100%;
      }
    }
  }
}

#review-panel {
  .chckt-sdk {
    .chckt-pm {
      border: none;
      &__details {
        padding-#{$ldirection}: 0;
        .chckt-input-field {
          border-radius: 0;
          border-color: #{$color-black};
          color: #{$color-black};
          height: 45px;
          &--cvc {
            max-width: 100%;
          }
          &--error {
            color: #{$color-red};
            border: 2px solid #{$color-red};
          }
          &--recurring {
            background: #{$color-white};
            border: 1px solid #{$color-black};
            border-radius: 0;
            height: 45px;
            padding: 8px 10px;
          }
        }
      }
      &__header {
        padding-#{$ldirection}: 0;
        .chckt-pm__radio-button {
          display: none;
        }
      }
      &__image {
        #{$rdirection}: 2px;
        margin: 0;
      }
      &__name {
        color: #{$color-black};
      }
    }
    .chckt-form--max-width {
      color: #{$color-black};
      max-width: 472px;
      min-width: 345px;
      .chckt-form-label {
        &__text {
          color: #{$color-gray};
          &--dark {
            #{$rdirection}: 20px;
            padding: 0 30px;
            text-transform: initial;
          }
        }
        &--exp-date {
          min-width: 182px;
          width: 46%;
        }
        &--cvc {
          float: #{$ldirection};
          max-width: 291px;
          min-width: 108px;
          padding-#{$ldirection}: 22px;
          width: 54%;
        }
        &__error-text {
          color: #{$color-red};
          text-transform: none;
        }
      }
    }
    .chckt-button-container {
      width: 90%;
      margin-bottom: 10px;
      .chckt-more-pm-button {
        border: none;
        padding-#{$ldirection}: 0;
        &__icon {
          display: none;
        }
        &__text {
          color: #{$color-black};
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 1px;
          margin-#{$ldirection}: 0;
          text-decoration: underline;
        }
      }
      .chckt-button {
        background: #{$color-black};
        margin-top: 0;
        padding: 10px;
        height: 40px;
        &--disabled {
          opacity: 1.7;
        }
      }
    }
  }
  .payment_form {
    width: 100%;
    .payment-container {
      border-top: 1px solid #{$color-black};
      width: 100%;
      .payment-option {
        border-bottom: 1px solid #{$color-black};
        min-height: 50px;
        width: 100%;
        padding: 10px;
        .payment_options-terms,
        .adyen-payment {
          display: none;
          margin-#{$ldirection}: 27px;
        }
        .paypal-terms {
          .local-submits {
            display: block;
            .paypal-express-btn {
              background: #{$color-black};
              color: #{$color-white};
              padding: 5px 40px;
            }
          }
        }
        input[type='radio'] {
          &:checked {
            & ~ label {
              .adyen-payment,
              .payment_options-terms {
                display: block;
              }
            }
          }
        }
        .clearpay-button {
          margin-bottom: 11px;
        }
        .related-media {
          display: inline-block;
          padding: 15px;
          vertical-align: baseline;
          &.cc {
            padding: 5px;
            .card-img {
              margin-#{$ldirection}: 6px;
              width: 40px;
            }
          }
          &.pp {
            height: 15px;
            padding: 0 3px;
            width: 80px;
          }
          &.cp {
            height: 37px;
            padding: 0 11px;
            width: 127px;
          }
        }
        .payment-terms {
          clear: both;
          padding-top: 8px;
          .overlay-wrapper {
            display: inline;
          }
        }
        .afterpay-button {
          height: 35px;
          padding: 0 32px;
        }
      }
    }
    .local-button {
      display: none;
    }
  }
  .sub-section {
    padding: 0 0 2em 0;
    .new-address {
      .form-item {
        input {
          width: 100%;
        }
      }
    }
    input[type='radio'] {
      @include swap_direction(margin, 0 0.5em 0 1em);
      &:first-child {
        @include swap_direction(margin, 0 0.5em 0 0);
      }
    }
    .view-address {
      @include swap_direction(margin, 0 0 2em 0);
      a.change-address {
        background: transparent;
        border-radius: 0;
        text-transform: capitalize;
        color: #026585;
        box-shadow: none;
        border: 0px;
        @include swap_direction(padding, 0 10px);
        font-weight: normal;
        text-decoration: underline;
      }
      .address-header {
        margin-top: 7px;
        padding: 0 15px;
      }
      .select-address-part {
        margin: 19px 15px;
      }
      .edit {
        height: 27px;
      }
    }
    .existing-address {
      div.form-item.select-menu {
        select {
          height: 23px;
          @include swap_direction(padding, 3px 0);
        }
      }
    }
  }
  border-bottom: 1px solid $color-grey;
  #checkout_billing_panel {
    overflow: hidden;
    div.form-item {
      label {
        margin-bottom: 0.5em;
        .afterpay-learnmore {
          position: absolute;
          margin-#{$ldirection}: 5px;
          border: none;
          span {
            background: #{$black};
            border-radius: 50%;
            height: 15px;
            width: 15px;
            vertical-align: sub;
            line-height: 1.3;
            text-align: center;
            color: #{$white};
            display: inline-block;
            text-transform: lowercase;
            cursor: pointer;
            margin-#{$ldirection}: 0;
          }
        }
      }
      span.payment-option {
        display: inline-block;
      }
    }
  }
  #checkout_payment {
    overflow: hidden;
    width: 100%;
    margin-#{$rdirection}: 0;
    &.adpl {
      select {
        background: #{$white};
        &::-ms-expand {
          display: block;
        }
      }
    }
  }
  div.form-item.checkbox {
    width: auto;
    &.creditcard-option {
      @include swap_direction(padding, 27px 0 8px 0);
      border-top: 1px solid #{$color-light-gray};
    }
    &.paypal-option {
      @include swap_direction(padding, 25px 0 20px 0);
      border-top: 1px solid #{$color-light-gray};
      border-bottom: 1px solid #{$color-light-gray};
    }
    &.afterpay-option-box {
      @include swap_direction(padding, 15px 0 20px 0);
      border-bottom: 1px solid #{$color-light-gray};
      .afterpay-img {
        @include swap_direction(margin, 0 1px 2px 1px);
        width: 109px;
      }
      .afterpay {
        font-weight: normal;
      }
      .afterpay-disclaimer {
        margin-#{$ldirection}: 32.3%;
        width: 60%;
        display: block;
        .purchase-agreement {
          color: #{$black};
          text-decoration: underline;
        }
      }
    }
  }
  div.form-item.card-expiry-date {
    overflow: hidden;
    width: 100%;
    select {
      float: $ldirection;
      width: 42% !important;
    }
    span {
      display: block;
      float: $ldirection;
      text-align: center;
      width: 6%;
    }
    span.label-content {
      display: block;
      float: none;
      text-align: $ldirection;
      width: 100%;
    }
  }
  div.form-item.card-cvn {
    input {
      width: 91%;
    }
  }
  input[type='checkbox'] {
    vertical-align: sub;
  }
  #checkout_review {
    .promotions-container {
      fieldset.fs {
        p {
          margin-top: 0;
        }
      }
    }
  }
  .payment-option {
    .related-media {
      @include swap_direction(padding, 10px 15px);
      img {
        margin-#{$rdirection}: 10px;
      }
    }
  }
  .adpl {
    .form-item.block {
      span.label-content {
        display: none;
      }
      &.card-type {
        .label-content {
          display: block;
          margin-bottom: 10px;
          font-weight: normal;
        }
      }
    }
    .form-item.card-expiry-date {
      span.label-content {
        display: none !important;
      }
    }
    .form-item.checkbox {
      span.label-content {
        display: inline !important;
      }
    }
  }
  #giftcard_balance {
    .checkbox {
      .label-content {
        display: block;
      }
    }
  }
}

section.panel {
  border: 1px solid $color-grey;
  &#promo-panel {
    border: none;
  }
  .block.right {
    float: right;
  }
  section {
    position: relative;
  }
  section.registration-form-container {
    min-height: 220px;
  }
  div.content {
    overflow: hidden;
    word-wrap: break-word;
    clear: both;
  }
  div.content.column {
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0);
  }
  div.column {
    float: $ldirection;
  }
  div.panel-group {
    @include swap_direction(margin, 1em 1em 0 1em);
    @include swap_direction(padding, 0 0 2em 0);
    overflow: hidden;
  }
  h3 {
    font-size: 1.2em;
    font-weight: bold;
    border-bottom-width: 0;
  }
  header {
    font-weight: bold;
    position: relative;
    background-color: $color-white;
    border-bottom: 1px solid $color-grey;
    display: block;
    overflow: auto;
    h2 {
      color: $color-black;
      font-weight: bold;
      line-height: 1.5em;
      font-size: 1.8em;
      float: $ldirection;
      @include swap_direction(padding, 0.3em 0.5em);
      @include swap_direction(margin, 0);
      .edit {
        text-decoration: none;
      }
    }
    a {
      color: $color-black;
    }
    a.edit.btn-mini {
      position: absolute;
      @include swap_direction(margin, 0);
      top: 0;
      #{$rdirection}: 0.6em;
      background: $color-black;
      border-radius: 0;
      color: $color-white;
      line-height: 26px;
    }
    div.item-count {
      margin-#{$ldirection}: 0.5em;
      color: $color-black;
      font-size: 1.4em;
      float: $ldirection;
      @include swap_direction(padding, 0.3em 0.5em);
      font-weight: bold;
      border-width: 0;
      @include swap_direction(margin, 0);
      line-height: 1.2em;
    }
    &:after {
      clear: both;
    }
  }
  &:after {
    clear: both;
  }
  .sub-section {
    @include swap_direction(margin, 0);
  }
  div.one-column-form {
    div.form-item {
      float: none;
      width: 100%;
    }
  }
  div.two-column-form {
    div.one-column-form {
      div.form-item {
        float: none;
        width: 100%;
      }
    }
    div.form-item {
      float: left;
      width: 40%;
      margin-#{$rdirection}: 5%;
      input[type='text'] {
        width: 100%;
      }
      &.title {
        width: 44%;
        float: none;
        clear: both;
      }
      &.checkbox {
        width: 100%;
      }
      &.address-to-use {
        width: 100%;
        .ship_group_1 {
          display: inline;
          margin-#{$rdirection}: 5px;
        }
      }
      &.default-shipping {
        width: auto;
        clear: $ldirection;
        input.field {
          width: auto !important;
        }
      }
      &.select-menu {
        margin-#{$ldirection}: 0;
        margin-#{$rdirection}: 0;
        width: 100%;
        select {
          width: 100%;
        }
      }
    }
    #qas {
      overflow: hidden;
      clear: both;
      margin-top: 1em;
      padding-top: 1em;
      border-top: dotted 1px $color-grey;
      div.form-item {
        input {
          width: 100% !important;
        }
      }
      div.manual-address {
        float: $ldirection;
        width: auto;
        clear: $ldirection;
      }
      div.qas-submit-container {
        float: $ldirection;
        width: auto;
        clear: $ldirection;
        input {
          width: auto;
        }
      }
    }
    #manual-address-fields {
      margin-top: 1em;
      overflow: visible;
    }
  }
  div.form-item {
    label.form-element-prefix {
      span {
        position: absolute;
      }
      input {
        margin-#{$ldirection}: 1%;
        width: 98%;
        display: inline;
      }
    }
  }
  div.sms-promo-wrapper {
    div.form-item {
      span.form-element-prefix {
        display: block;
        float: left;
        margin: 8px 6px 0 0px;
      }
    }
  }
  &.hidden {
    display: none;
  }
  &.disabled {
    display: none;
  }
  .content {
    @include swap_direction(padding, 1em);
  }
  input[type='checkbox'] ~ label,
  input[type='checkbox'] ~ .label {
    display: inline;
    & .label-content {
      display: inline;
      @include swap_direction(margin, 0);
    }
  }
}

div.column.left {
  float: $ldirection;
  clear: $ldirection;
  width: 65%;
  padding-#{$rdirection}: 2em;
  section.panel {
    @include swap_direction(margin, 0px);
    border-bottom-width: 0px;
    header {
      div.item-count {
        position: absolute;
        #{$rdirection}: 0.6em;
        font-weight: normal;
      }
    }
    input[type='radio'] {
      text-indent: 0;
      display: inline-block;
      width: 16px;
      @include swap_direction(margin, 0);
      vertical-align: middle;
    }
  }
  .sign-in-panel {
    .panel-group {
      header {
        margin-bottom: 7px;
        h3 {
          margin-bottom: 7px;
          font-size: 1.2em;
          font-weight: bold;
          border-bottom-width: 0;
        }
      }
      input {
        width: 90%;
      }
      #checkout_signin_guest_user {
        .tooltip {
          font-size: 11px;
        }
      }
    }
    span#reset-contianer {
      display: block;
    }
  }
  #review-panel {
    #checkout_payment {
      width: 50%;
    }
  }
  #shipmethod-panel {
    margin-bottom: 0;
    float: $rdirection;
    width: 100%;
    header {
      float: $rdirection;
      background: transparent;
      padding-bottom: 0;
      @include swap_direction(margin, 0 13px 0 0);
    }
    .content {
      padding-top: 0;
      float: $rdirection;
      clear: both;
    }
    .ship-method-group-label {
      float: $rdirection;
      margin-top: 5px;
      input {
        @include swap_direction(margin, 0 5px 0 15px);
      }
      label {
        div.overlay-wrapper {
          display: inline;
          float: none;
        }
        a {
          padding-left: 10px;
          font-style: italic;
        }
      }
    }
    .ship-method-group,
    .ship-method-group-label {
      width: 100%;
      overflow: hidden;
      text-align: $rdirection;
    }
    .ship-method-collection-group {
      label {
        float: $rdirection;
        margin-top: 10px;
        clear: both;
        text-align: $rdirection;
      }
    }
  }
  .promotions-container {
    clear: both;
  }
}

// !important is used below to override the inline styles from 3rd party content
.product-full {
  &__details {
    .afterpay-paragraph {
      margin-bottom: 20px;
      .afterpay-link {
        background: $color-black;
        width: 15px;
        height: 15px;
        text-decoration: none !important;
        color: $color-white !important;
        vertical-align: text-bottom;
        border-radius: 50%;
        text-align: center;
        font-size: 12px;
        line-height: 1.4;
        margin-#{$ldirection}: 6px;
      }
      .afterpay-text2 {
        vertical-align: bottom;
      }
      .afterpay-logo-link {
        margin-#{$ldirection}: 2px;
        vertical-align: bottom;
      }
    }
  }
}

div.column.right {
  float: $rdirection;
  clear: $rdirection;
  width: 35%;
  section.panel {
    @include swap_direction(margin, 0 0 1em 0);
  }
  #viewcart-panel {
    div.cart-item {
      div.desc {
        width: 46%;
      }
    }
  }
  #order-summary-panel {
    .afterpay {
      display: none;
    }
  }
  #shipmethod-panel {
    border-bottom: 3px solid $color-grey;
    .ship-method-group-label {
      overflow: hidden;
      float: $ldirection;
      width: 100%;
      @include swap_direction(padding, 5px 0 0 0);
      input,
      label {
        display: block;
        vertical-align: middle;
        width: 92%;
        float: left;
        padding-bottom: 10px;
        margin: 0 0 3px 0;
        height: auto;
      }
      input {
        width: 8%;
        margin-bottom: 0;
      }
      a.overlay-link {
        text-align: $rdirection;
        display: block;
        float: $rdirection !important;
      }
      .more-info-icon-popup {
        float: $rdirection;
      }
    }
  }
  #order-summary-panel {
    @include swap_direction(margin, 0 0 1em 0);
    border-bottom: 3px solid $color-grey;
    header {
      h2 {
        float: none;
        padding-#{$ldirection}: 0;
        @include swap_direction(margin, 0);
        border: none;
      }
      float: none;
      @include swap_direction(margin, 0 13px 0 13px);
    }
  }
  .samples-panel {
    .product-img {
      img {
        width: 55%;
      }
    }
  }
  #past-purchases-panel {
    .product-img {
      width: 100%;
    }
    p {
      font-size: 13px;
    }
  }
}

div.messages {
  div.single-message {
    border: none;
    color: $color-red;
    font-size: 12px;
    font-weight: normal;
    background-color: $color-white;
    @include swap_direction(padding, 0 0 12px 10px);
    a {
      border-bottom: none;
      color: $color-black;
      text-decoration: underline;
    }
    .close_link {
      display: none;
    }
    .forgot-pass-gdpr {
      .btn {
        color: #{$color-white};
        text-decoration: none;
      }
    }
  }
}

#confirm {
  #confirmation-panel {
    padding: 0 0 2em 0;
    h2.checkout__subtitle {
      font-size: 1.4em;
      font-weight: bold;
      letter-spacing: 0.03em;
      padding-top: 3px;
      @include swap_direction(margin, 0 0 15px 0);
    }
    .confirmation-panel__confirm-text--donation {
      margin-bottom: 20px;
      background-color: #f0f0f0;
      @include swap_direction(padding, 3% 2% 1% 3%);
    }
    border-bottom-width: 1px;
    .actions {
      padding: 0 0 0 1em;
    }
  }
  #registration-panel.panel.active {
    border-bottom: 1px solid $color-grey;
    border-top: 0px !important;
    section.how--we-will-use-container {
      div.how-will-we-use {
        width: auto;
        border: 1px solid #ccc;
        clear: right;
        float: right;
        padding: 1em;
        p {
          margin: 0;
        }
      }
    }
    section.registration-form-container {
      div.form-item {
        width: 100%;
        .password-field {
          &__info {
            @include swap_direction(margin, 5px 0);
            width: 31%;
            &::before {
              top: 24%;
              right: 96%;
            }
          }
        }
      }
    }
    div.promotions-container {
      clear: both;
    }
    p.pre {
      white-space: normal;
    }
    p.note {
      width: 35%;
    }
  }
  #sign-in-confirmation-panel {
    border-bottom-width: 1px;
  }
}

#viewcart-panel {
  div.cart-header {
    overflow: hidden;
    @include swap_direction(padding, 0.8em 0.6em);
    border-bottom: 1px solid $color-grey;
    font-weight: bold;
    div.products {
      width: 53%;
      text-align: $ldirection;
    }
    div.price {
      width: 18%;
    }
    div.qty {
      width: 15%;
    }
    div.total {
      width: 13%;
      float: $rdirection;
    }
    div {
      text-align: center;
      line-height: 1em;
      float: $ldirection;
    }
  }
  div.cart-item {
    @include swap_direction(padding, 1.5em 0);
    border-bottom: 1px solid $color-grey;
    div.column {
      text-align: center;
      @include swap_direction(padding, 0);
      line-height: 1em;
    }
    div.thumb {
      width: 20%;
    }
    div.desc {
      width: 33%;
      position: relative;
      text-align: $ldirection;
      .product_name {
        text-transform: uppercase;
      }
      div.size {
        @include swap_direction(padding, 5px 0);
      }
      div.price {
        display: none;
      }
    }
    div.price {
      width: 18%;
    }
    div.qty {
      width: 15%;
      height: 158px;
    }
    div.total {
      width: 13%;
      float: $rdirection;
    }
    div.color {
      width: 100%;
      float: none;
    }
  }
  div.cart-item.shaded {
    div.color {
      bottom: 0;
    }
  }
  header {
    h2 {
      .edit {
        color: $color-black;
      }
    }
  }
  .sub_line {
    display: none;
  }
  .content {
    display: block;
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0);
  }
}

section.viewcart-buttons-panel {
  clear: both;
  div.content {
    border-bottom: none;
  }
  .adyen_checkout_button {
    .paypal-smart-button {
      float: #{$ldirection};
      margin: 0 25px;
    }
    .continue-checkout {
      height: 43px;
      line-height: 3.5;
    }
  }
  .continue-buttons {
    text-align: $rdirection;
    float: $rdirection;
    a {
      display: inline-block;
      float: none;
      vertical-align: top;
      &.paypal-checkout {
        height: 30px;
        margin-right: 10px;
        width: 160px;
        img {
          margin-right: 5px;
          width: 100%;
        }
        &.disabled {
          text-align: center;
          width: 170px;
          height: 54px;
          cursor: default;
          background-size: 76%;
          background: url(/media/images/checkout/pay-pal-grey.png) top center no-repeat;
          img {
            visibility: hidden;
            padding-bottom: 4px;
          }
          span {
            color: #ccc;
            margin-top: 0px;
            display: inline-block;
          }
        }
      }
      &.continue-checkout {
        margin-left: 10px;
      }
      .btn.btn-primary {
        background: $color-black;
        text-decoration: none;
        border-radius: 0;
      }
    }
    .or {
      line-height: 30px;
    }
  }
  a.continue-shopping {
    width: 120px;
  }
}

#recommended-products-panel {
  border-bottom: 1px solid $color-grey;
  display: block;
  overflow: auto;
  .recommended-product-items {
    .recommended-item {
      display: table;
      width: 33%;
      float: $ldirection;
      height: 170px;
      @include swap_direction(padding, 0 3%);
      a {
        float: none;
      }
      div.description {
        float: none;
        width: 100%;
        margin-bottom: 1em;
      }
      div.formatted_price {
        margin-top: 0.5em;
        clear: both;
      }
    }
  }
}

#guarantee-panel {
  margin-top: 1em;
  border-bottom: 1px solid $color-grey;
  header {
    border-bottom: 1px solid $color-grey;
    @include swap_direction(margin, 0 20px);
    h2 {
      padding-top: 12px;
      padding-#{$ldirection}: 0px;
    }
  }
  div.content {
    @include swap_direction(padding, 1em 1.5em);
  }
}

#order-summary-panel {
  border-top-width: 0;
  border-bottom-width: 0;
  div.value {
    color: $color-black;
  }
  .label {
    width: 70%;
    text-align: $rdirection;
    float: $ldirection;
    clear: $ldirection;
  }
  .value {
    width: 30%;
    text-align: $rdirection;
    float: $ldirection;
    font-weight: bold;
    padding-#{$rdirection}: 1em;
  }
  header {
    float: $rdirection;
    border-bottom: 1px solid $color-grey;
    display: block;
    overflow: auto;
    @include swap_direction(margin, 0 13px 0 0);
    @include swap_direction(padding, 0.4em 0em);
  }
  div.total {
    border-top: none;
    padding-top: 0;
  }
  .afterpay {
    @include swap_direction(margin, 20px 0 10px 0);
    width: 100%;
    vertical-align: middle;
    .afterpay-learnmore {
      border: none;
      span {
        background: #{$black};
        border-radius: 50%;
        height: 16px;
        width: 16px;
        line-height: 1.4;
        text-align: center;
        color: #{$white};
        display: inline-block;
        text-transform: lowercase;
        cursor: pointer;
        padding-#{$ldirection}: 1px;
      }
    }
    img {
      @include swap_direction(margin, 0 1px 3px 1px);
      max-width: 108px;
    }
  }
}

.row {
  clear: both;
}

.messages {
  @include swap_direction(margin, 1em);
  font-size: 1.2em;
  font-weight: bold;
  @include swap_direction(padding, 0px);
  border-bottom: none;
}

fieldset.fs {
  p {
    margin-bottom: 10px;
  }
}

heading {
  @include checkout_heading;
}

#links-panel {
  @include checkout_heading;
  .mobile_hidden {
    a {
      white-space: nowrap;
    }
  }
  ul.links_list {
    li {
      margin-bottom: 0.5em;
      font-size: 13px;
    }
  }
}

#favorites-panel {
  @include checkout_heading;
  border-bottom: 3px solid $color-grey;
}

#need-help-panel {
  @include checkout_heading;
  .email,
  .phone,
  .chat {
    margin-#{$ldirection}: 5px;
    padding: 5px 0;
    font-size: 19px;
  }
  h4 {
    font-weight: bold;
    text-transform: none;
  }
  border-bottom: 3px solid $color-grey;
}

#gift-options-display {
  margin-#{$ldirection}: 40px;
  clear: none;
  width: 41%;
}

#footer {
  background-color: $color-black;
  height: inherit;
}

.chat.mobile_hidden {
  h4 {
    margin-#{$ldirection}: 0px;
  }
}

#tab-switcher {
  div.content {
    border-bottom: none;
  }
}

#checkout-review-panel {
  margin-top: -1em;
  section.divide {
    &:first-of-type {
      padding-top: 0;
      border-top-width: 0;
    }
  }
}

#checkout_review {
  .form-item {
    position: relative;
  }
  div.submit {
    text-align: $rdirection;
    .afterpay-button {
      min-width: 253px;
      text-align: #{$ldirection};
      background: url(/media/images/checkout/cp-logo-white-transparent.svg) #{$black} no-repeat 94% 50%;
      height: 35px;
      padding-#{$ldirection}: 2%;
    }
  }
}

.afterpay_modal_wrapper {
  .headline {
    line-height: 1;
  }
}

#checkout_shipping_panel {
  .select-address {
    .SELECTED_ADDRESS_ID {
      padding-#{$rdirection}: 25px;
      height: 25px;
    }
  }
}

#return-user {
  form#checkout_signin {
    margin-top: 27px !important;
  }
}

body.mobile {
  .mobile_hidden {
    display: none;
  }
  .pc_hidden {
    display: block;
  }
}

#promo-panel.panel {
  .banner {
    margin-bottom: 1em;
  }
}

body#index {
  #mobile-breadcrumb {
    display: none;
  }
  #viewcart-panel {
    .viewcart-buttons-panel {
      display: none;
    }
    border-bottom-width: 0;
    margin-bottom: 0;
    display: block;
    .content {
      display: block;
      padding-bottom: 0;
    }
  }
  .cart-header {
    display: none;
  }
  .price {
    display: none;
  }
  .product {
    .qty {
      min-width: 4em;
      width: 22%;
      margin-top: 0;
    }
    .qty-label {
      display: inline;
    }
    .total {
      text-align: $rdirection;
      width: 23%;
      float: $rdirection;
    }
    .thumb {
      width: 20%;
    }
    .desc {
      width: 35%;
    }
  }
  .product.shaded {
    .color {
      position: absolute;
      bottom: 1em;
      #{$ldirection}: 0;
      width: 100%;
      .swatch {
        position: absolute;
        top: 0px;
        #{$ldirection}: 0;
      }
      .shade {
        position: absolute;
        top: 0px;
        #{$ldirection}: 20px;
      }
      .shade-label {
        display: none;
      }
    }
  }
}

#viewcart {
  .donation_amounts_panel {
    h2.donation-panel__title {
      text-align: $ldirection;
      border-bottom: none;
      margin-bottom: 0px;
      background-color: #eff0f0 !important;
      margin: 2% 0%;
      padding: 3% 0% 3% 0%;
      letter-spacing: 0.01em;
    }
    .donation-group {
      label {
        display: inline-block;
        vertical-align: middle;
        font-size: 11px;
      }
    }
    #select-amount-mobile {
      display: none;
    }
    span#select-amount {
      font-weight: bold;
      font-size: 15px;
      padding: 0% 6% 0% 4%;
    }
    span {
      padding: 0% 2% 0% 2%;
    }
    float: $ldirection;
    width: 100%;
    span.donation-group {
      input.field {
        width: 15px !important;
      }
    }
  }
  .donation_info_panel {
    padding: 2% 0%;
    float: $ldirection;
    width: 100%;
    .donation_logo {
      float: $ldirection;
      height: 100%;
      padding: 0px 20px 0px 0px;
    }
    .donation_logo_mobile {
      display: none;
      float: $ldirection;
      height: 100%;
    }
    .donation_message {
      margin-left: 125px;
      font-size: 12px;
    }
    .donation_message_mobile {
      display: none;
      margin: 0% 0% 0% 25%;
      font-size: 14px;
    }
  }
}

a.btn {
  background-color: #005288;
  font-size: 12px;
  letter-spacing: 2px;
  border: 1px solid #dbdbdb;
  font-weight: bold;
}

#security-code-panel {
  margin: 0px;
  .content {
    width: auto;
  }
  header {
    @include swap_direction(padding, 0.4em 1em);
  }
}

#cboxContent {
  #cboxLoadedContent {
    .site-header,
    .site-footer {
      display: none;
    }
    .site-content {
      .cs-page {
        &__content {
          float: none;
          width: 100%;
        }
        &__navigation {
          display: none;
        }
      }
    }
  }
}

.gnav-util {
  &--cart {
    .cart-block {
      &__items {
        .cart-item {
          &__info {
            width: 100px;
          }
        }
      }
    }
  }
}

/*** ApplePay integration ***/

/* User not logged in */

.viewcart-buttons-panel {
  &.applepay {
    &.panel {
      .content {
      }
    }
    .continue-buttons {
      .continue-checkout,
      .paypal-checkout {
        display: inline-block;
      }
      .continue-checkout {
        float: right;
      }
      .or {
        float: right;
        display: inline;
        margin-top: 12px !important;
      }
      &.applepay-enabled {
        float: right;
        position: relative;
        width: auto;
        height: 120px;
        padding: 0 !important;
        margin-bottom: 30px;
        text-align: center;
        .continue-checkout,
        .paypal-checkout {
          position: absolute;
          display: block !important;
          float: none;
          width: 100%;
          margin: 0;
        }
        .continue-checkout {
          top: 60px;
        }
        .paypal-checkout {
          bottom: 0;
        }
        .or {
          position: absolute;
          top: 27px;
          float: none;
          display: block;
          width: 100%;
          text-align: center;
          margin: 12px 0 !important;
        }
      }
    }
    .applepay-integration-wrapper {
      float: right;
      width: 100%;
      padding: 10px 13px;
      border: 1px solid #b7b7b7;
      background: #ebebeb;
      &.applepay-error {
        border-color: #e61f26;
        background: #ffe2e4;
      }
      div.applepay-button-container {
        width: 65%;
        .divide {
          position: relative;
          padding-top: 0;
          padding-right: 45px;
          border: 0 none;
          input[type='checkbox'] {
            position: absolute;
            top: calc(50% - 4px);
            right: 10px;
            width: 15px;
            height: 15px;
          }
          .label-content {
            a {
              display: inline !important;
            }
          }
        }
      }
      span.applepay-button-container {
        width: 35%;
        text-align: center;
        .apple-pay-button {
          display: block !important;
        }
      }
      .applepay-button-container {
        display: inline-block;
        vertical-align: middle;
        text-align: justify;
        .apple-pay-button {
          overflow: hidden;
          text-indent: 100%;
          white-space: nowrap;
          -webkit-appearance: -apple-pay-button;
          -apple-pay-button-style: black;
        }
        .apple-pay-button.button-style-buy {
          -apple-pay-button-type: buy;
        }
        .apple-pay-button.button-style-set-up {
          -apple-pay-button-type: set-up;
        }
      }
      @media (max-width: 767px) {
        .continue-shopping {
          position: static;
          float: left;
          display: block;
          width: auto;
        }
      }
      @media (min-width: 40em) and (max-width: 641px) {
        span.applepay-button-container {
          display: block;
          float: left;
          padding-left: 0;
        }
      }
      @media (max-width: 40em) {
        .applepay-button-container {
          display: block;
          min-width: 100%;
          padding-left: 0;
          .btn {
            display: block;
            width: 100%;
          }
        }
      }
    }
    .js-applepay-error-container {
      color: #eb3473;
      padding-top: 10px;
      padding-left: 5px;
      float: left;
    }
  }
}
/* 640px */

@media (max-width: 40em) {
  .viewcart-buttons-panel {
    &.applepay {
      &.panel {
        .content {
          float: none;
        }
      }
      .continue-shopping {
        margin-bottom: -1em !important;
      }
      .continue-buttons {
        &.applepay-enabled {
          width: 100%;
          height: 120px;
          .continue-checkout,
          .paypal-checkout {
            float: none !important;
            width: 100% !important;
            text-align: center;
          }
          .continue-checkout {
            width: 100%;
            margin-bottom: 7px !important;
            text-align: center;
          }
          .paypal-checkout {
            img {
              width: auto;
            }
          }
          .or {
            position: absolute;
            top: 23px;
            width: 100% !important;
          }
        }
      }
      .applepay-integration-wrapper {
        display: table;
        table-layout: fixed;
        float: none;
        clear: both;
        width: 100%;
        margin: 0;
        section.divide {
          padding-top: 10px;
        }
        div.applepay-button-container {
          display: table-footer-group;
          float: none;
          width: auto;
          text-align: left;
          .error {
            margin: 5px 0 !important;
            background: transparent !important;
          }
        }
        span.applepay-button-container {
          display: table-header-group;
          float: none;
          width: auto;
        }
      }
    }
  }
}
/* User logged in */

.elc-user-state-logged-in {
  &#viewcart {
    .viewcart-buttons-panel {
      &.applepay {
        .continue-shopping {
          margin: 0;
          width: 130px;
        }
      }
    }
  }
  .viewcart-buttons-panel {
    &.applepay {
      padding-left: 0;
      .continue-shopping {
        margin: 0;
        padding: 5px;
      }
      .continue-buttons {
        &.applepay-enabled {
          float: right;
          position: static;
          width: auto;
          height: auto;
          margin-bottom: 0;
          font-size: 0;
          line-height: 0;
          .or {
            display: none;
          }
          .paypal-checkout,
          .continue-checkout {
            position: static;
            display: inline-block !important;
            float: none;
            width: auto;
            vertical-align: top;
          }
          .paypal-checkout {
            width: 165px;
            height: auto;
            margin-right: 4px;
            img {
              height: inherit;
              margin-right: 0;
            }
          }
        }
      }
      .applepay-integration-wrapper {
        width: auto;
        background: transparent;
        border: 0 none;
        margin-right: 4px;
        padding: 0;
        div.applepay-button-container {
          display: none;
        }
        span.applepay-button-container {
          width: 140px;
          height: 35px;
        }
      }
      @media (max-width: 1024px) {
        padding-left: 10px;
        .continue-shopping {
          position: static;
          width: auto;
        }
        .continue-buttons {
          &.applepay-enabled {
            width: 170px;
            .paypal-checkout,
            .continue-checkout {
              display: block !important;
              margin: 0 auto 20px !important;
            }
          }
        }
        .applepay-integration-wrapper {
          clear: both;
          margin: 0;
          span.applepay-button-container {
            width: 170px;
          }
        }
      }
      @media (max-width: 40em) {
        .continue-shopping {
          margin-bottom: 1em !important;
        }
        .applepay-integration-wrapper {
          display: block;
          float: right;
          clear: both;
          width: auto;
          margin: 0;
          section.divide {
            padding-top: 10px;
          }
          .applepay-button-container {
            .btn {
              width: 135px;
              height: 35px;
            }
          }
          div.applepay-button-container {
            display: none;
          }
          span.applepay-button-container {
            display: block;
            float: none;
            width: auto;
          }
        }
      }
      @media (max-width: 480px) {
        .continue-shopping {
          float: none;
          width: 100%;
          text-align: center;
        }
        .continue-buttons {
          &.applepay-enabled {
            float: none;
            width: auto;
            margin: 0 auto;
            .paypal-checkout,
            .continue-checkout {
              width: 135px !important;
              margin: 0 auto 20px !important;
            }
          }
        }
        .applepay-integration-wrapper {
          float: none;
          margin: auto;
          .applepay-button-container {
            .btn {
              margin: auto;
            }
          }
        }
      }
    }
  }
}

/*** End of ApplePay integration ***/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  div.column.right {
    #shipmethod-panel {
      .ship-method-group-label {
        width: 100%;
        label {
          margin-left: 5px;
        }
      }
    }
    section {
      &.panel {
        .ship-method-group {
          &.ship-method-home-group {
            select {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

#shipping-panel {
  &.substate-manual {
    #manual-address-fields {
      display: block !important;
      visibility: visible !important;
    }
  }
}

.clearpay-popup {
  &#colorbox {
    #cboxContent {
      margin-top: 10px;
      #cboxLoadedContent {
        @include swap_direction(padding, 15px 20px 10px);
      }
      #cboxClose {
        @include icon('close', before);
        top: 0;
        right: 0;
      }
    }
  }
}

.paypal-overlay {
  background: #{$black};
  #{$ldirection}: 0;
  height: 100%;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.paypal-processing {
  #{$ldirection}: 0;
  margin: 0 auto;
  padding: 10px;
  position: fixed;
  #{$rdirection}: 0;
  text-align: center;
  top: 25%;
  width: 100%;
  z-index: 999;
  .process-content {
    background: #{$white};
    max-width: 335px;
    margin: 0 auto;
    position: relative;
    padding: 30px 10px;
    z-index: 999;
  }
  .lab-logo {
    padding-bottom: 28px;
  }
}

.cs-container {
  h2.title {
    border-bottom: 1px solid #000000;
    color: #333333;
    font-size: 16px;
    font-weight: normal;
    line-height: 23px;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: bold;
    clear: both;
  }
  .single-col,
  .second-col {
    float: left;
  }
  .jumpLinks {
    margin-bottom: 20px;
  }
  .top_customer_service {
    float: right;
    margin: 10px 0;
  }
  .cs-page__content-item {
    padding: 0 20px;
  }
  &.cs-container__customer-service {
    .left-column,
    .middle-column {
      width: 280px;
      float: left;
      margin: 0 25px 0 3px;
      clear: none;
    }
    .middle-column {
      margin-left: 0;
    }
    ul {
      margin-bottom: 20px;
    }
    ol li a,
    ul li a {
      color: #026585;
      text-decoration: underline;
    }
  }
  &__consumer-awareness {
    .single-col {
      margin: 0 25px 10px 3px;
      width: 664px;
    }
    .second-col {
      width: 210px;
    }
  }
}

//left nav
#customer-service {
  border: 1px solid #ccc;
  float: left;
  margin: 0 0 10px 0;
  padding: 0;
  width: 258px;
  h3 {
    color: #666;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    margin: 12px 0 12px 12px;
    padding: 0;
    border-bottom: none;
  }
  p {
    line-height: 16px;
    margin: 0 12px 5px;
    color: #666;
    font-size: 11px !important;
    &.phone {
      margin: 10px 12px;
      background: url('/media/images/customerservice/contactPhone.gif') 0 3px no-repeat;
      text-indent: 18px;
    }
  }
}

.cs-page {
  .top_customer_service {
    text-align: right;
  }
  .jumpLinks {
    margin-bottom: 10px;
  }
}

.supplier-relations {
  padding: 0 15px;
}

///
/// @file components/prouducts/_spp.scss
///
/// \brief Styles for multi-product pages
///
///

#cboxLoadedContent {
  padding: 40px 35px 30px 35px;
}
